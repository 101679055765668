<template>
  <div>
    <van-popup
      v-model="show"
      position="top"
      style="
        top: 20vh;
        left: 7vw;
        right: 7vw;
        width: auto;
        border-radius: 0.7rem;
      "
    >
      <div class="form-popup">
        <h1>请完善信息</h1>
        <div class="input">
          <input
            v-model="form.street"
            placeholder="请选择地区"
            @click="showArea = true"
          />
        </div>
        <!-- <div class="input">
          <input
            v-model="form.userType"
            placeholder="请选择类型"
            @click="showType = true"
          />
        </div> -->
        <div class="input">
          <button
            :class="{ active: form.street && form.userType }"
            @click="submitCode"
          >
            确定
          </button>
        </div>
      </div>
    </van-popup>
    <van-action-sheet
      v-model="showType"
      :actions="typeActions"
      @select="onSelectType"
    />
    <van-action-sheet
      v-model="showArea"
      :actions="areaActions"
      @select="onSelectArea"
    />
  </div>
</template>
<script>
import { Toast } from 'vant'

export default {
  name: 'Form',
  data() {
    return {
      show: true,
      showType: false,
      showArea: false,
      form: {
        userType: '群众',
        street: ''
      },
      typeActions: [
        { name: '行政机关' },
        { name: '医疗机构' },
        { name: '疾控机构' },
        { name: '监督执法机构' },
        { name: '群众' }
      ],
      areaActions: [
        { name: '南京市' },
        { name: '无锡市' },
        { name: '徐州市' },
        { name: '常州市' },
        { name: '苏州市' },
        { name: '南通市' },
        { name: '连云港市' },
        { name: '淮安市' },
        { name: '盐城市' },
        { name: '扬州市' },
        { name: '镇江市' },
        { name: '泰州市' },
        { name: '宿迁市' },
        { name: '外省' }
      ]
    }
  },
  methods: {
    onSelectType(item) {
      this.showType = false
      this.form.userType = item.name
    },
    onSelectArea(item) {
      this.showArea = false
      this.form.street = item.name
    },
    goHome() {
      this.$router.push('/home')
    },
    submitCode() {
      //  || !this.form.userType
      if (!this.form.street) return
      this.$http({
        url: '/xt-exam/etUser/updateUser',
        method: 'post',
        data: this.form
      }).then(res => {
        if (res.code === 200) {
          Toast.success('保存成功！')
          this.goHome()
        }
      })
    }
  }
}
</script>
<style lang="less">
.form-popup {
  width: 86vw;
  border-radius: 0.71rem;
  h1 {
    margin: 14px 0 24px;
    text-align: center;
    font-size: 1.19rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #010e4f;
  }
  p {
    margin: 0;
    text-align: center;
    font-size: 28px;
    color: #1c064b;
    letter-spacing: 2px;
  }
  .input {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    input {
      width: calc(100% - 100px);
      height: 36px;
      border-radius: 18px;
      background-color: #f9f8f9;
      border: 2px solid #d0cfd0;
      color: #0c024f;
      outline: none;
      padding: 0 14px;
    }
    button {
      width: calc(100% - 68px);
      height: 40px;
      border-radius: 18px;
      border-style: none;
      outline: none;
      background-color: #d3d3d3;
      color: #fff;
    }
    .active {
      background-color: #010e4f;
    }
  }
}
</style>
